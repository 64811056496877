import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import io from "socket.io-client";
import { API_SOCKET_HOST } from "./constants";
let socketConn: any = null;

// Простое кэш-хранилище
const cache = new Map();

const CACHE_DURATION = 5 * 60 * 1000; // 5 минут

// Функция для создания уникального ключа для кэширования
const generateCacheKey = (
  url: string,
  method: string,
  params: any,
  data: any,
) => {
  return JSON.stringify({ data, method, params, url });
};

export interface IRequestResponse {
  hasError: boolean;
  isOk: boolean;
  getSuccessMessage: () => string;
  getFailMessage: () => string;
  getData: () => any;
}
const FAIL = "fail";
const SUCCESS = "success";

export const request: any = async ({
  data,
  headers = {},
  method,
  params,
  url,
  useLoading,
  useCache,
  cacheTimeout = 5 * 60 * 1000,
  withCredentials,
  responseType,
}: any) => {
  try {
    headers = {
      ...headers,
    };

    if (useCache) {
      const cacheKey = generateCacheKey(url, method, params, data);
      const cachedEntry = cache.get(cacheKey);

      if (cachedEntry) {
        const { result, timestamp } = cachedEntry;

        // Проверяем, не устарели ли данные в кэше
        if (Date.now() - timestamp < cacheTimeout) {
          return result; // Возвращаем закэшированный результат
        } else {
          // Удаляем устаревшие данные из кэша
          cache.delete(cacheKey);
        }
      }
    }

    const result = await axios({
      data,
      headers: headers,
      method: method || "get",
      params,
      responseType,
      url,
      withCredentials: withCredentials || false,
    });

    if (useCache) {
      const cacheKey = generateCacheKey(url, method, params, data);
      cache.set(cacheKey, {
        result: getResponseData(result.data),
        timestamp: Date.now(),
      });
    }

    return getResponseData(result.data);
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    console.log(err);
    return getResponseData({
      error: {
        errorMessage: "Произошла ошибка в момент отправки запроса",
      },
      status: "fail",
    });
  }
};

export const getResponseData = (result: any) => {
  const resultOfRequest: IRequestResponse = {
    getData: () => {
      return result.data;
    },
    getFailMessage: () => {
      if (result && result.error) {
        return result.error.errorMessage;
      }
      return;
    },
    getSuccessMessage: () => {
      if (result && result.status === SUCCESS) {
        return result.message;
      }
      return;
    },
    hasError: result.status === FAIL,
    isOk: result.status === SUCCESS,
  };

  return resultOfRequest;
};

export const socketConnection = () => {
  if (socketConn) {
    return socketConn;
  }
  // const targetFunction = () => {
  //   console.log("Target function called");
  // };

  // const handler = {
  //   apply: (target: any, thisArg: any, argumentsList: any) => {
  //     console.log("Proxy: function called with arguments:", argumentsList);
  //     // Вы можете вызвать целевую функцию, если это нужно
  //     return target.apply(thisArg, argumentsList);
  //   },
  // };

  // const socket = new Proxy(targetFunction, handler);

  const socket: any = new Proxy(
    io(API_SOCKET_HOST as string, {
      transports: ["websocket"],
      withCredentials: true,
    }),
    {
      get: function (target, prop, receiver) {
        if (prop === "on") {
          return function (
            eventName: string,
            callback: (data: IRequestResponse) => any,
          ) {
            const interceptedCallback = function (data: any) {
              callback(getResponseData(data));
            };
            target.on(eventName, interceptedCallback);
          };
        }
        return Reflect.get(target, prop, receiver);
      },
    },
  );

  socket.send = (name: string, data: any, callback: () => any) => {
    socket.emit(name, data);
    callback && socket.on(name, callback);
  };
  socketConn = socket;
  return socket;
};

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: "" },
): BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    responseType?: AxiosRequestConfig["responseType"];
    headers?: AxiosRequestConfig["headers"];
    withCredentials?: AxiosRequestConfig["withCredentials"];
  },
  unknown,
  unknown
> => request;

export const api = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: "" }),
  endpoints: () => ({}),
  reducerPath: "api",
});
