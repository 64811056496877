import { useEffect, useState } from "react";
import Mob from "./Mob";
import store from "../../../services/store.service";
import styles from "./Mob.module.scss";

import React from "react";
import { IRequestResponse, socketConnection } from "../../../core/api/common";
import { useSelector } from "react-redux";
import { getFullDataMobFromShort } from "../../../services/base/mob";
import Boss from "./Boss";
import { GROUP_ELEMENT_AT_LOCATION_ID } from "../../../services/base/constants";
import ReactDOM from "react-dom";
import TimeEnd from "../base/TimeEnd/TimeEnd";

function Mobs() {
  const mobsData: any = useSelector((store: any) => store.mobs.mobData);
  const bossData: any = useSelector((store: any) => store.mobs.bossData);
  const { groupEnd } = useSelector((store: any) => store.other);

  const [damages, changeDamages]: any = useState({});
  const [mobs, changeMobs]: any = useState([]);
  const [socket]: any = useState(socketConnection());

  const parentEl: any = document.getElementById(GROUP_ELEMENT_AT_LOCATION_ID);
  const [time, setTime]: any = useState(null);
  const [waves, setWaves]: any = useState(null);

  useEffect(() => {
    setTime(null);
    changeMobs([]);
  }, [groupEnd]);

  useEffect(() => {
    let latestActionId: any = null;

    socket.on("get active group action id", (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }
      const actionId = resp.getData();
      if (actionId !== latestActionId) {
        socket.send("get active group data", null);
        latestActionId = actionId;
      }
    });

    socket.on("get active group data", (groupData: any) => {
      if (groupData.hasError) {
        setTime(null);
        return;
      }

      const { mobs, time, waves } = groupData.getData();

      setTime(time);
      changeMobs(genMobs(mobs));
      setWaves(waves);
    });

    socket.on("attack mob", (resp: IRequestResponse) => {
      if (resp.hasError) return;
      const damageResults = resp.getData();

      changeDamages((oldValues: any) => {
        const target = oldValues[damageResults.uniqId] || [];
        target.push(damageResults);
        return {
          ...oldValues,
          [damageResults.uniqId]: target,
        };
      });
    });

    return () => {
      socket.off("get active group data");
      socket.off("attack mob");
      socket.off("get active group action id");
    };
  }, []);

  useEffect(() => {
    changeDamages({});
    socket.send("get active group data", null);
    changeMobs([]);
  }, [store.getState().location.id]);

  function genMobs(mobs: any) {
    if (!mobs) return [];
    mobs = mobs.map((mob: any, idx: number) => {
      if (!mob) return null;
      const fullMob: any = getFullDataMobFromShort(mob);

      const npcData =
        fullMob.type === "mob" ? mobsData[fullMob.id] : bossData[fullMob.id];

      return {
        ...npcData,
        ...fullMob,
        posId: idx,
      };
    });

    return mobs;
  }

  return (
    <>
      {mobs.length ? (
        <div id="mobs-parent">
          {mobs.map((mob: any, index: number) => {
            if (!mob) return "";
            const params = {
              ...mob,
              damages: damages[mob.uniqId] || [],
              targetId: index,
            };

            return params.uniqId ? (
              params.type === "mob" ? (
                <Mob key={`${params.uniqId}`} params={params}></Mob>
              ) : (
                <Boss key={`${params.uniqId}`} params={params}></Boss>
              )
            ) : (
              ""
            );
          })}
          {parentEl && time
            ? ReactDOM.createPortal(
                <>
                  <div className={styles.Time}>
                    <TimeEnd isUpdate={true} time={time} />
                  </div>
                  <div className={styles.Waves}>Этап: {waves}</div>
                </>,
                parentEl,
              )
            : null}
        </div>
      ) : null}
    </>
  );
}

export default Mobs;
